import SeezSdk from './sdk.js'
import SeezarButton from './widget.js'

window.seezSdk = new SeezSdk()
customElements.define('seez-sdk-seezar', SeezarButton)

window.seezSdk.addEventListener('targetSiteLoaded', () => {
  const components = document.querySelector('[data-inject-component]')?.getAttribute('data-inject-component')?.split(',') ?? []
  components.forEach(c => document.body.appendChild(document.createElement(c)))
})

window.onload = () => {
  window.seezSdk.injectSeezOnlineBuying()
}
