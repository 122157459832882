export async function sha256(message) {
  if (crypto.subtle == null) return '' //Page is not HTTPS, not encryting
  return Array.from(new Uint8Array(await crypto.subtle.digest('SHA-256', new TextEncoder().encode(message))))
    .map(b => b.toString(16).padStart(2, '0'))
    .join('')
}

export function toIsoString(date) {
  const tzo = -date.getTimezoneOffset()
  const pad = num => (num < 10 ? '0' : '') + num
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}${tzo >= 0 ? '+' : '-'}${pad(Math.floor(Math.abs(tzo) / 60))}:${pad(Math.abs(tzo) % 60)}`
}
